<template>
  <v-app>
    <v-dialog v-model="showDialog" width="600">
      <v-card>
        <v-card-title class="headline font-weight-bold">
          <span>Erro ao realizar o cadastro!</span>
        </v-card-title>

        <v-card-text class="text-center"> {{ errorMessage }} </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showDialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid fill-height class="grey lighten-4">
      <v-row align="center" justify="center">
        <v-col xs="10" sm="6" md="4" xl="4" class="pa-3">
          <v-row justify="center">
            <v-col cols="12">
              <v-card :loading="loading" elevation="12">
                <div class="">
                  <v-img src="@/assets/img/loginCover.png"> </v-img>
                </div>
                <v-card-text>
                  <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form @submit.prevent="submit" class="ma-3">
                      <!-- Campo code -->
                      <!-- <validation-provider
                        v-slot="{ errors }"
                        name="code"
                        rules="required"
                      >
                        <v-text-field
                          v-model="code"
                          :error-messages="errors"
                          required
                          label="Code:"
                          prepend-icon="mdi-code"
                          color="primary"
                        ></v-text-field>
                      </validation-provider> -->

                      <!-- Campo password -->
                      <v-text-field
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show ? 'text' : 'Senha'"
                        label="Senha:"
                        hint="Preencha o campo"
                        class="input-group--focused"
                        prepend-icon="mdi-lock"
                        @click:append="show = !show"
                        v-model="password"
                        :rules="passwordRules"
                        color="primary"
                      ></v-text-field>

                      <v-text-field
                        :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showConfirm ? 'text' : 'password'"
                        label="Confirme a senha:"
                        hint="Confirme a senha:"
                        class="input-group--focused"
                        prepend-icon="mdi-lock"
                        v-model="passwordConfirmation"
                        @click:append="showConfirm = !showConfirm"
                        :rules="passwordConfirmationRules"
                        color="primary"
                      ></v-text-field>

                      <v-btn
                        :disabled="invalid && !updatingPassword"
                        @click="
                          resetPassword(code, password, passwordConfirmation)
                        "
                        block
                        class="actionButton font-weight-bold mt-3"
                      >
                        Atualizar password
                        <v-progress-circular
                          v-show="updatingPassword"
                          indeterminate
                          color="white"
                        ></v-progress-circular>
                      </v-btn>
                    </v-form>
                  </validation-observer>
                  <v-spacer>
                    <v-btn
                      color="actionButton"
                      text
                      small
                      block
                      @click="backToLogin"
                    >
                      <span class="grey--text text--darken-1"
                        >Ir para a página de
                      </span>
                      <span class="ml-2 primary--text">LOGIN</span>
                    </v-btn>
                  </v-spacer>
                </v-card-text>
                <v-alert
                  type="success"
                  icon="mdi-check"
                  class="pa-2"
                  v-model="successAlert"
                >
                  Senha atualizada com sucesso!
                </v-alert>
                <v-alert
                  type="error"
                  icon="mdi-alert"
                  class="pa-2"
                  v-model="errorAlert"
                  >{{ errorMsg }}</v-alert
                >
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="loadingSnackBar"
        shaped
        absolute
        :timeout="timeout"
        >{{ snackbarText }}</v-snackbar
      >
    </v-container>
  </v-app>
</template>
<script>
import corsolarApi from "../../../../libraries/corsolar/corsolarApi";
import strapiApi from "../../../../libraries/strapi/strapiApi";
export default {
  name: "ResetPassword",

  data() {
    return {
      code: "",
      snackbarText: "Usuário Criado com Sucesso!",
      loadingSnackBar: false,
      timeout: 5000,
      loading: false,
      showDialog: false,
      errorMessage: "",
      successAlert: false,
      saveAlert: false,
      errorAlert: false,
      updatingPassword: false,
      errorMsg: "",
      code: "",
      user: {},
      returnMessage: "",
      show: false,
      showConfirm: false,
      password: "",
      passwordConfirmation: "",
      // aqui é feito a verificação da password
      passwordRules: [
        (valuepass) => !!valuepass || "Insira uma senha.",
        (valuepass) =>
          /(?=.*[A-Z])/.test(valuepass) ||
          "Insira no mínimo uma letra maiúscula",
        (valuepass) =>
          /(?=.*\d)/.test(valuepass) || "insira no mínimo um número",
        (valuepass) =>
          /([!@$%])/.test(valuepass) ||
          "Insira no mínimo um caractére especial",
        (valuepass) => valuepass.length >= 8 || "Mínimo de 8 caracteris",
      ],
      // aqui onde é feita a verificação para ver se o user digitou a mesma password
      passwordConfirmationRules: [
        (value) => !!value || "Confirme a senha.",
        (value) => value === this.password,
      ],
    };
  },
  created() {},
  computed: {},

  async mounted() {
    this.code = this.$route.query.code;
    //console.log(this.$route.query.code)
  },
  methods: {
    backToLogin() {
      this.$router.push("/login");
    },
    // função chaamada para salvar o user no banco
    async resetPassword(code, password, passwordConfirmation) {
      if (
        password == "" ||
        password.length < 6 ||
        password !== passwordConfirmation
      ) {
        this.errorAlert = true;
        this.errorMsg = "Verifique sua nova senha e tente novamente!";
        setTimeout(this.closeAlerts, 2000);
        return;
      }
      this.updatingPassword = true;

      const ans = await strapiApi.users.resetPassword(
        code,
        password,
        passwordConfirmation
      );

      console.log(JSON.stringify({ ans }));
      this.openAlerts(ans);
      this.updatingPassword = false;
    },
    closeAlerts() {
      this.saveAlert = false;
      this.errorAlert = false;
      this.successAlert = false;
    },
    openAlerts(condition) {
      if (condition.jwt) {
        this.successAlert = true;
      } else if (condition?.error) {
        this.errorAlert = true;
        this.errorMsg = condition?.error?.message;
      }
      setTimeout(this.closeAlerts, 2000);
    },
  },
};
</script>
