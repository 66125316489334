<template>
  <v-app id="inspire">
    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-card-title class="headline font-weight-bold">
          <span>Erro ao realizar o login!</span>
        </v-card-title>

        <v-card-text class="text-center"> {{ message }} </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-content> -->
    <v-container fluid fill-height style="" class="custom-container ma-0 pa-0">
      <v-img
        src="@/assets/img/logoWhite.png"
        style="position: fixed; width: 300px; right: 50px; bottom: 50px"
      >
      </v-img>
      <v-row align="center" justify="center">
        <v-col cols="12" xs="12" sm="8" md="4" xl="4">
          <v-card
            class="elevation-12"
            style="background-color: rgb(255, 255, 255, 0.6)"
          >
            <!-- <v-img src="@/assets/img/loginCover.png" class="white--text">
            </v-img> -->

            <br />
            <v-img
              src="@/assets/img/logoDark.png"
              class="white--text justify-center my-10"
              style="width: 300px; margin: auto"
            >
            </v-img>
            <br />

            <v-card-title primary-title class="justify-center"
              ><h3>Acesse sua conta</h3></v-card-title
            >
            <br />
            <v-card-text>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <v-form @submit.prevent="submit" class="ma-3">
                  <!-- Campo Email -->
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="email"
                      :error-messages="errors"
                      required
                      prepend-icon="mdi-account"
                      name="email"
                      label="Login"
                      ref="email"
                      color="primary"
                    ></v-text-field>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="password"
                    rules="required"
                  >
                    <v-password-field
                      v-model="password"
                      id="password"
                      label="Senha:"
                      :error-messages="errors"
                    >
                    </v-password-field>
                  </validation-provider>
                </v-form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="invalid"
                    block
                    class="actionButton my-0"
                    @click="doLogin"
                    draggable="true"
                  >
                    <b v-show="!loading">
                      Entrar
                      <v-icon class="ma-1">mdi-login</v-icon>
                    </b>

                    <v-progress-circular
                      v-show="loading"
                      indeterminate
                      color="white"
                    ></v-progress-circular>
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" text @click="forgotPassowrd">
                Esqueci minha senha
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="doRegister">
                Registrar-se
              </v-btn>
            </v-card-actions>

            <v-card-actions>
              <v-spacer></v-spacer>
              <span style="font-size: 12px; margin: 15px"
                >Corsolar | Monitoramento 2.0</span
              >
            </v-card-actions>

            <v-card-actions>
              <p class="ma-2" style="color: gray">Problemas com acesso?</p>
              <p class="ma-2" style="color: gray">
                Fale conosco: monitoramento@corsolar.com.br
              </p>
            </v-card-actions>

            <!-- monitoramento@corsolar.com.br -->
          </v-card>
        </v-col>
      </v-row>
      <v-card
        class="flex ma-0 pa-0"
        style="
          background-color: rgb(23, 27, 102, 0.8);
          position: absolute;
          bottom: 0px;
          width: 100vw;
          margin: 0px;
          padding: 0px;
        "
      >
        <v-card-title>
          <a
            href="https://www.corsolar.com.br"
            style="color: white; text-decoration: none; margin-left: 20px"
          >
            www.corsolar.com.br</a
          >
          <v-spacer></v-spacer>

          <v-btn v-for="link in links" :key="link.icon" class="mx-4" dark icon>
            <a
              :href="link.href"
              style="color: white; text-decoration: none; margin-left: 20px"
            >
              <v-icon size="24px">
                {{ link.icon }}
              </v-icon></a
            >
          </v-btn>
        </v-card-title>
      </v-card>
    </v-container>
    <!-- </v-content> -->
  </v-app>
</template>
<script>
import corsolarApi from "../../../../libraries/corsolar/corsolarApi";
import strapiApi from "../../../../libraries/strapi/strapiApi";
import { mapState } from "vuex";
import VPasswordField from "../../components/fields/VPasswordField.vue";
export default {
  name: "login",
  components: {
    VPasswordField,
  },

  data() {
    return {
      showConfirm: false,
      saveEmail: false,
      loading: false,
      dialog: false,
      message: null,
      email: "",
      password: "",
      loading: false,
      ans: {},
      msgForgotPassword: "",
      showAlert: false,
      myInterval: null,
      links: [
        {
          href: "https://www.facebook.com/corsolaroficial",
          icon: "mdi-facebook",
        },
        {
          href: "https://www.instagram.com/corsolaroficial",
          icon: "mdi-instagram",
        },
        {
          href: "https://www.linkedin.com/company/corsolaroficial",
          icon: "mdi-linkedin",
        },
        {
          href: "https://www.youtube.com/channel/UCz0dqXRgk5TXCKtByRTIRUA",
          icon: "mdi-youtube",
        },
        {
          href: "https://api.whatsapp.com/send?phone=551122275000&text=Fale%20com%20um%20vendedor!",
          icon: "mdi-whatsapp",
        },
      ],
    };
  },

  created() {},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  async mounted() {
    if (localStorage.email) this.email = localStorage.email;

    this.saveEmail = localStorage.saveEmail;
  },
  methods: {
    async solarViewLogin() {},
    async doRegister() {
      this.$router.push("/register");
    },

    async doLogin() {
      this.loading = true;
      let res = "";
      let user;

      const { email, password } = this;
      try {
        res = await strapiApi.users.login(email, password);
        if (res.error) {
          console.log(res.error.message);
          this.message = res.error.message;
          this.dialog = true;
          this.loading = false;
          return;
        }
        let token = res?.jwt;
        this.$store.commit("SET_TOKEN", token);

        user = await strapiApi.users.getById(res?.user?.id);
        if (!user) {
          this.message = "Aguardando aprovação";
          this.dialog = true;
          this.loading = false;
          return;
        }
        user.uid = user?.email;

        console.log(JSON.stringify({ user }));
        this.$store.commit("SET_USER", user);
        this.$router.push("/stations");
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.message = e;
        this.dialog = true;
        this.loading = false;
        return;
      }
    },

    async forgotPassowrd() {
      let res = "";
      let user;
      const { email } = this;

      if (!email) {
        this.message = "Favor preencher email válido";
        this.dialog = true;
      }

      try {
        res = await strapiApi.users.forgotPassword(email);
        console.log(JSON.stringify({ res }));
        if (res?.error) {
          //console.log(res.error.message);
          this.message = res.error.message;
          this.dialog = true;
          return;
        }

        if (res?.ok) {
          this.message = "Enviado email para redefinição de senha";
          this.dialog = true;
          return;
        }
      } catch (e) {
        console.log(e);
        this.message = e;
        this.dialog = true;
        return;
      }
    },
  },
};
</script>

<style scoped>
.custom-container {
  background-image: url("~@/assets/img/background.png");
  background-size: cover;
}
</style>
